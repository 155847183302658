











































import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';

export default {
  name: 'Question530',
  components: {
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        studentAnswer: null,
        language: 'fr',
      },
      options: [
        {text: 'Structure A', value: 'a'},
        {text: 'Structure B', value: 'b'},
        {text: 'Structure C', value: 'c'},
      ],
    };
  },
  computed: {
    imageName() {
      return '/img/assignments/q530.png';
    },
  },
};
